<template>
    <section class="calendario-listado container-fluid">
        <navbar-admin icon="icon-calendar-multiselect" />
        <div class="row justify-content-center border-bottom pb-2">
            <div class="col-auto">
                <p class="f-16 f-600"> Abril - Mayo </p>
            </div>
        </div>
        <section class="list-day">
            <titulo-divisor titulo="Lunes">
                <div class="row">
                    <div class="col-auto icon-option">
                        <i class="icon-plus-box f-25 cr-pointer" @click="crearTarea" />
                    </div>
                </div>
            </titulo-divisor>
            <div v-for="(data,idx) in 2" :key="idx" class="row listado-por-dia">
                <div class="col-md-2 col-lg-1">
                    <div class="bg-f5 br-4">
                        <div class="bg-general f-12 text-white border-general br-t-4 text-center">Abr.</div>
                        <div class="f-18 f-700 d-middle-center border-bottom border-right border-left py-1">
                            29
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <div class="bg-f5 br-4 p-2 border mb-3">
                        <div class="row mx-0">
                            <div class="col-lg-7 f-12">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class="f-700">Preparar cotización</p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Tipo de actividad: 
                                            <span class="f-300"> Comercial </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class=""> 7:30 am - 8:30 am </p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Subtipo de actividad:
                                            <span class="f-300"> Marketing </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 col-lg-3 mt-2 mt-lg-0">
                                <div class="bg-white border rounded-pill d-middle tres-puntos my-1">
                                    <img src="/img/pruebas/person1.png" alt="" class="wh-26 rounded-pill bg-white">
                                    <p class="ml-2 f-12 tres-puntos">Juan Camilo Ramirez</p>
                                </div>
                            </div>
                            <div class="col-4 col-lg-2">
                                <div class="d-flex justify-content-end icon-option">
                                    <el-tooltip content="Editar" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-pencil mr-2 cr-pointer f-20" @click="editarTarea" />
                                    </el-tooltip>
                                    <el-tooltip content="Eliminar" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-trash-can-outline mr-2 cr-pointer f-20" @click="eliminarTarea" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <hr class="my-1" />
                        <div class="row">
                            <div class="col-12">
                                <p class="f-11 lh-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias illo saepe sit animi, quae maxime voluptate molestias quaerat eius sed earum recusandae minima dignissimos ut officiis, ullam veritatis assumenda iure?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- partials  -->
        <modal-crear-tarea ref="modalCrearTarea" />
        <modal-eliminar ref="modalEliminarTarea" title="Eliminar tarea" mensaje="¿Está seguro que quiere eliminar esta tarea?" />
    </section>
</template>

<script>
export default {
    components:{
        modalCrearTarea: () => import('./partials/modalCrearTarea.vue'),
    },
    data() {
        return {
            optionsOp: [
                {
                    value: 1,
                    label: 'Oportunidad 1'
                }, 
                {
                    value: 2,
                    label: 'Oportunidad 2'
                }, 
                {
                    value: 3,
                    label: 'Oportunidad 3'
                }, 
                {
                    value: 4,
                    label: 'Oportunidad 4'
                }, 
                {
                    value: 5,
                    label: 'Oportunidad 5'
                }
            ],
            optionsProyectos: [
                {
                    value: 1,
                    label: 'Proyecto 1'
                }, 
                {
                    value: 2,
                    label: 'Proyecto 2'
                }, 
                {
                    value: 3,
                    label: 'Proyecto 3'
                }, 
                {
                    value: 4,
                    label: 'Proyecto 4'
                }, 
                {
                    value: 5,
                    label: 'Proyecto 5'
                }
            ],
            optionsEtapas: [
                {
                    value: 1,
                    label: 'Etapa 1'
                }, 
                {
                    value: 2,
                    label: 'Etapa 2'
                }, 
                {
                    value: 3,
                    label: 'Etapa 3'
                }, 
                {
                    value: 4,
                    label: 'Etapa 4'
                }, 
                {
                    value: 5,
                    label: 'Etapa 5'
                }
            ],
            optionsConfiguraciones: [
                {
                    value: 1,
                    label: 'Configuración 1'
                }, 
                {
                    value: 2,
                    label: 'Configuración 2'
                }, 
                {
                    value: 3,
                    label: 'Configuración 3'
                }, 
                {
                    value: 4,
                    label: 'Configuración 4'
                }, 
                {
                    value: 5,
                    label: 'Configuración 5'
                }
            ],
            optionsMaterials: [
                {
                    value: 1,
                    label: 'Material 1'
                }, 
                {
                    value: 2,
                    label: 'Material 2'
                }, 
                {
                    value: 3,
                    label: 'Material 3'
                }, 
                {
                    value: 4,
                    label: 'Material 4'
                }, 
                {
                    value: 5,
                    label: 'Material 5'
                }
            ],
            optionsPlans: [
                {
                    value: 1,
                    label: 'Plan 1'
                }, 
                {
                    value: 2,
                    label: 'Plan 2'
                }, 
                {
                    value: 3,
                    label: 'Plan 3'
                }, 
                {
                    value: 4,
                    label: 'Plan 4'
                }, 
                {
                    value: 5,
                    label: 'Plan 5'
                }
            ],
            optionsActividades: [
                {
                    value: 1,
                    label: 'Actividad 1'
                }, 
                {
                    value: 2,
                    label: 'Actividad 2'
                }, 
                {
                    value: 3,
                    label: 'Actividad 3'
                }, 
                {
                    value: 4,
                    label: 'Actividad 4'
                }, 
                {
                    value: 5,
                    label: 'Actividad 5'
                }
            ],
            valueSelect: '',
            valueProyectos: '',
            valueEtapas: '',
            valueMateriales: '',
            valueConfiguraciones: '',
            valuePlanes: '',
            valueActividades: '',
            disabledSelects: true
        }
    },
    async created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'calendario.main',
            'calendario.lista',
        ]);
    },
    methods: {
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        filterAccept1(){
            this.disabledSelects = false
        },
        crearTarea(){
            this.$refs.modalCrearTarea.toggle()
        },
        editarTarea(){
            this.$refs.modalCrearTarea.toggle()
        },
        eliminarTarea(){
            this.$refs.modalEliminarTarea.toggle()
        },
    },
}
</script>

<style lang="scss" scoped>
.calendario-listado{
    .list-day{
        background-color: white;
    }
}
</style>